<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="안전보건 목표"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :columnSetting="false"
        :isExcelDown="false"
        :isFullScreen="false"
        :filtering="false"
        :usePaging="false"
        selection="multiple"
        rowKey="saiSafetyHealthyDetailId"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable"
              label="엑셀업로드"
              icon="upload"
              @btnClicked="excelUploadData" />
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addMaster" />
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="saveData"
              mappingType="PUT"
              label="저장" 
              icon="save" 
              @beforeAction="saveMaster"
              @btnCallback="saveMasterCallback" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template>
            <c-text-column
              :editable="editable"
              :col="col"
              :props="props"
              @datachange="datachange(props, col)"
            />
            <div v-if="editable">
              <q-btn-group outline class="ColumInnerBtnGroup">
                <q-btn
                  icon="add"
                  color="red-6"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props)">
                  <q-tooltip>
                    {{$language('안전보건 목표별 항목 추가')}}
                  </q-tooltip>
                </q-btn>
              </q-btn-group>
            </div>
          </template>
        </template>
      </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'safety-healthy-mst',

  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiSafetyHealthyId' },
          { index: 1, colName: 'saiSafetyHealthyId' },
        ],
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'plant',
          },
          {
            required: true,
            name: 'targetName',
            field: 'targetName',
            label: '안전보건 목표',
            align: 'left',
            style: 'width:290px',
            sortable: false,
            type: 'custom',
          },
          {
            required: true,
            name: 'detailName',
            field: 'detailName',
            label: '세부추진사항',
            align: 'left',
            sortable: false,
            type: 'text',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '해당부서',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'deptMulti',
            deptCd: 'deptCd',
            isFirstValue: false,
          },
          {
            name: 'kpi',
            field: 'kpi',
            label: 'KPI성과지표',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false
          },
          // {
          //   name: 'useFlag',
          //   field: 'useFlag',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:50px',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [],
      isSave: false,
      editable: true,
      saveUrl: '',
      listUrl: '',
      saveData: [],
      shLabel: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.safetyHealthy.base.list.url;
      this.saveUrl = transactionConfig.sai.safetyHealthy.base.save.url;
      this.deleteUrl = transactionConfig.sai.safetyHealthy.base.delete.url;
      // this.dtllistUrl = selectConfig.sai.safetyHealthy.base.detail.list.url;
      // this.dtlSaveUrl = transactionConfig.sai.safetyHealthy.base.detail.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    datachange(props) {
      if (props.row['mstEditFlag'] !== 'C') {
        props.row['mstEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name !== 'gubunName' && props.row['itemEditFlag'] !== 'C') {
        props.row['itemEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    addMaster() {
      this.grid.data.splice(0, 0, {
        saiSafetyHealthyId: uid(),  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        targetName: '',  // 안전보건목표
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자
        mstEditFlag: 'C',
        itemEditFlag: 'C'
      })
    },
    innerBtnClicked(col, props) {
      this.grid.data.splice(props.rowIndex, 0, {
        saiSafetyHealthyDetailId: uid(),  // 안전보건목표 세부추진사항 일련번호
        plantCd: props.row.plantCd,  // 사업장
        targetName: props.row.targetName,  // 안전보건목표
        saiSafetyHealthyId: props.row.saiSafetyHealthyId,  // 안전보건목표 일련번호
        detailName: '', // 세부추진사항
        deptCd: null,  // 해당부서
        kpi: '',  // kpi 성과지표
        useFlag: 'Y',  // 사용여부
        regUserId: this.$store.getters.user.userId,  // 등록자
        itemEditFlag: 'C'
      })
    },
    saveMaster() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        /**
         * [안전보건목표 - 항목] 데이터 분리
         * 분리시에 수정&등록된 데이터만 처리
         */
        let saveData = this.grid.data.filter(item => {
          return item.mstEditFlag === 'C' || item.mstEditFlag === 'U' 
            || item.itemEditFlag === 'C' || item.itemEditFlag === 'U'
        });
        this.$set(this.$data, 'saveData', this.$_.map(this.$_.uniqBy(saveData, 'saiSafetyHealthyId'), (mst) => {
          return {
            saiSafetyHealthyId: mst.saiSafetyHealthyId,  // 법규등록부 구분 일련번호
            plantCd: mst.plantCd,
            targetName: mst.targetName,
            useFlag: 'Y',  // 사용여부
            editFlag: mst.mstEditFlag,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            items: this.$_.map(this.$_.filter(saveData, { saiSafetyHealthyId: mst.saiSafetyHealthyId }), (item) => {
              return {
                saiSafetyHealthyId: mst.saiSafetyHealthyId,  // 법규등록부 구분 일련번호
                saiSafetyHealthyDetailId: item.saiSafetyHealthyDetailId,  // 법규등록부 항목 일련번호
                detailName: item.detailName,  // 세부추진사항
                deptCd: item.deptCd,  // 해당부서
                kpi: item.kpi,  // kpi성과지표
                useFlag: item.useFlag,  // 사용여부
                sortOrder: item.sortOrder,
                editFlag: item.itemEditFlag,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              }
            })
          }
        }));
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',  
            message: '저장하시겠습니까?',   
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveMasterCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '안전보건목표 기준정보 업로드';
      this.popupOptions.target = () => import(`${'./safetyHealthyMstExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let mst_data = [];
        let mst_data_targetName = [];
        this.$_.forEach(s_data, item => {
          item.useFlag = 'Y';
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          if (this.$_.indexOf(mst_data_targetName, item.targetName) < 0) {
            mst_data_targetName.push(item.targetName)
            mst_data.push({
              saiSafetyHealthyId: uid(),  // 안전보건목표 일련번호
              plantCd: item.plantCd,  // 사업장
              targetName: item.targetName,  // 안전보건목표
              useFlag: 'Y',  // 사용여부
              regUserId: this.$store.getters.user.userId,  // 등록자
              items: [],
            })
          }
        })
        this.$_.forEach(mst_data, _mst => {
          this.$_.forEach(s_data, item => {
            if (_mst.targetName == item.targetName) {
              item.useFlag = 'Y';
              item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
              _mst.items.push({
                saiSafetyHealthyId: _mst.saiSafetyHealthyId,  // 법규등록부 구분 일련번호
                saiSafetyHealthyDetailId: uid(),  // 법규등록부 항목 일련번호
                detailName: item.detailName,  // 세부추진사항
                deptCd: item.deptCd,  // 해당부서
                kpi: item.kpi,  // kpi성과지표
                useFlag: 'Y',  // 사용여부
                sortOrder: item.sortOrder,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              })
            }
          })
        })

        this.$http.url = this.saveUrl + '/excel';
        this.$http.type = 'POST';
        this.$http.param = mst_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
